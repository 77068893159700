// Colors (Ticketag for Teams):

$background:            rgb(255, 255, 255);     // Change Me
$button-delete:         rgb(239, 30, 30);       // Change Me
$button-text:           rgb(255, 255, 255);     // Change me
$title:                 rgb(0, 33, 94);        // Change me
$main-text:             rgb(94, 120, 145);      // Change me
$error-text:            rgb(239, 30, 30);       // Change me
$main-accent:           rgb(0, 84, 255);      // Change me
$lines-and-box:         rgb(228, 226, 233);     // Change me
$text-footer:           rgb(255, 255, 255);     // Change me
$main-icon:             rgb(0, 33, 94);        // Change me
$delete-icon:           rgb(239, 30, 30);       // Change me

// Gradients (Ticketag)

// Button
$first-button:  rgb(11, 72, 196);      // Change me first gradient
$second-button: rgb(0, 72, 148);       // Change me second gradient
$button: linear-gradient(262.12deg, $first-button 2.46%, $second-button 100%);

// Clickable Links
$first-clickable-links:  rgb(11, 72, 196);     // Change me first gradient
$second-clickable-links: rgb(0, 72, 148);      // Change me second gradient
$clickable-links: linear-gradient(262.12deg, $first-clickable-links 2.46%, $second-clickable-links 100%);

// Overlay
$first-overlay:  rgba(0, 33, 94, 0);   // Change me first gradient
$second-overlay: rgba(0, 33, 94, 1);   // Change me second gradient
$overlay: linear-gradient(180.51deg, $first-overlay 50%, $second-overlay 99.56%);

// Footer
$first-footer:  rgb(0, 72, 148);       // Change me first gradient
$second-footer: rgb(0, 33, 94);        // Change me second gradient
$footer-bg: linear-gradient(96.8deg, $first-footer 2.08%, $second-footer 95.95%);

// Disabled icon
$first-disabled-icon:   rgb(161, 183, 206);     // Change me first gradient
$second-disabled-icon:  rgb(164, 183, 219);     // Change me second gradient
$disabled-icon: linear-gradient(262.12deg, $first-disabled-icon 2.46%, $second-disabled-icon 100%);


// Useful colors
$theme-colors: (
        "heading-color": $title,
        "paragraph-color": $main-text,
        "card-color" : $main-accent
);

$body-color: map_get($theme-colors, "paragraph-color");
$headings-color: map_get($theme-colors, "heading-color");

// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,800');

$font-family-montserrat: 'Montserrat', sans-serif;
$font-family-base: $font-family-montserrat;

$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;

$font-weight-base: $font-weight-medium;
$headings-font-weight: $font-weight-medium;

$form-feedback-valid-color: $body-color;

$font-sizing-definition: (
        h1: (
                sm: 24px,
                md: 30px,
                lg: 48px
        ),
        h2: (
                sm: 18px,
                md: 24px,
                lg: 36px
        ),
        h3: (
                sm: 16px,
                md: 18px,
                lg: 24px
        ),
        h4: (
                sm: 14px,
                md: 16px,
                lg: 18px
        ),
        h5: (
                sm: 12px,
                md: 14px,
                lg: 16px
        ),
        p: (
                sm: 12px,
                md: 14px,
                lg: 14px
        ),
        a: (
                sm: 14px,
                md: 10px,
                lg: 10px
        )
);
$label-font-size: 12px;
$label-2-font-size: 10px;

$height-icon-eye: 11px;


